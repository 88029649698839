<template>
  <div>
    <label v-if="label" class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">{{ label }}<span v-show="isLabelRequire" class="text-red"> *</span></label>
    <textarea
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      :class="[
        withIcon ? 'pl-11' : 'pl-3',
        height ? height : 'h-32',
        {
          'is-invalid': hasErrors,
          'bg-grey cursor-not-allowed': disabled,
          'cursor-not-allowed': disabledWhite,
          'border-grey-field border': borderEnabled
        }
      ]"
      class="pr-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg text-base resize-none"
      :disabled="disabled || disabledWhite"
    ></textarea>
    <span class="text-xs text-gray-500 leading-tight inline-block mt-1" v-if="notes">{{ notes }}</span>
    <transition name="fade" mode="out-in">
      <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    title: String,
    notes: String,
    disabled: Boolean,
    disabledWhite: {
      type: Boolean,
      default: false
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    enterKeyAction: {
      type: Function
    },
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: Boolean,
    height: {
      type: String,
      default: 'h-32'
    },
    isLabelRequire: { type: Boolean, default: false }
  },
  computed: {
    hasErrors() {
      return !!this.error.length
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.rounded-lg {
  border-radius: 8px !important;
}
</style>
