var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v(_vm._s(_vm.label)), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLabelRequire,
      expression: "isLabelRequire"
    }],
    staticClass: "text-red"
  }, [_vm._v(" *")])]) : _vm._e(), _c('textarea', _vm._b({
    staticClass: "pr-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg text-base resize-none",
    class: [_vm.withIcon ? 'pl-11' : 'pl-3', _vm.height ? _vm.height : 'h-32', {
      'is-invalid': _vm.hasErrors,
      'bg-grey cursor-not-allowed': _vm.disabled,
      'cursor-not-allowed': _vm.disabledWhite,
      'border-grey-field border': _vm.borderEnabled
    }],
    attrs: {
      "disabled": _vm.disabled || _vm.disabledWhite
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  }, 'textarea', _vm.$attrs, false)), _vm.notes ? _c('span', {
    staticClass: "text-xs text-gray-500 leading-tight inline-block mt-1"
  }, [_vm._v(_vm._s(_vm.notes))]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.error ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }